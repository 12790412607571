
<template>
	<LayoutDefault>

		<ToggleSwitch
		:is-checked="sortByGrabbed"
		msg="Sort By Last Grabbed"
		@toggle-it="toggleSortByGrabbed"
		/>

		<ToggleSwitch
		:is-checked="sortByProvisional"
		msg="Show Provisional"
		@toggle-it="toggleSortByProvisional"
		/>

		<div class="w-full flex flex-wrap flex-col">

			<div 
			v-for="x in sorted_pulls"
			:key="x.matchstring"
			class="text-left w-full lg:w-1/2 flex flex-row flex-nowrap mb-4 border-2 rounded-md"
			:class="{'border-blue-200': x.provisional, 'border-green-200': parseInt(x.arc_last) == parseInt(x.next_number)}"
			>

			<div class="cover flex-none">
				<img 
				:src="x.coverimage" 
				alt="(cover)" 
				class="h-36 aspect-auto rounded-md"
				>
			</div>		
			<div class="maincontent flex-1 relative ml-4">
				
				<div class="text-xl font-bold mb-2">
					<div class="inline-block mr-4 align-middle">
						<a 
						:href="x.preview" 
						title="info from leagueofcomicgeeks.com" 
						target="_new"
						>
						<InformationCircleIcon 
						class="h-6 w-6 text-blue-500"
						/>
					</a>
				</div>
				{{x.name}} 
			</div>

			<div class="place-items-end">
				{{x.publisher}}
			</div>

			<div
			v-if="x.last_grabbed !== null"
			class="place-items-end "
			>(last_grabbed: {{x.last_grabbed}})</div>

			<div class="absolute bottom-0 left-12">
				<div v-if="changing == x.matchstring">
					<input
					v-model="x.arc_last"
					type="text"
					class="w-8 rounded-md mr-2 border-2"
					><a
					href="#"
					class="inline-block mr-2 "
					@click.prevent="setArcEnd(x)"
					><CheckCircleIcon class="h-4 w-4 text-green-500"/></a><a
					href="#"
					class="inline-block mr-2 "
					@click.prevent="changing = ''"
					><MinusCircleIcon class="h-4 w-4 text-red-500"/></a>
				</div>
				<span v-else-if="!isNaN(parseInt(x.arc_last))"><VariableIcon class="h-6 w-6 text-blue-500 inline-block mr-1"/>to {{x.arc_last}}<a
					href="#"
					class="inline-block ml-2"
					@click.prevent="changing = x.matchstring"
					><PencilSquareIcon class="h-4 w-4 text-blue-500 inline-block "/></a></span>
					<span v-else><a
						href="#"
						@click.prevent="changing = x.matchstring"
						><VariableIcon class="h-6 w-6 text-blue-500 inline-block mr-1"/>set</a></span>
					</div>

					<div
					v-if="x.provisional"
					class="absolute bottom-0 right-0 px-2 rounded-tl bg-blue-200"
					>Provisional</div>

				</div>
				<div class="options flex-none relative">

					<div class="place-items-end"><a
						href="#"
						title="remove from watchlist"
						@click.prevent="unwatchbook(x)"
						><TrashIcon class="mt-2 h-6 w-6 text-blue-500" /></a></div>

						<div
						v-if="x.provisional"
						class="absolute bottom-0 bg-blue-200 w-full"
						><a
						href="#"
						title="keep on watchlist permanently"
						@click.prevent="removeProvisional(x)"
						><HandThumbUpIcon class="h-6 w-6 text-black"/></a></div>

					</div>

				</div>
			</div>
		</LayoutDefault>
	</template>

	<script>
		import _ from 'lodash';
		import ToggleSwitch from '@/components/ToggleSwitch'
		import { 
			TrashIcon, 
			InformationCircleIcon, 
			CheckCircleIcon, 
			MinusCircleIcon,
			HandThumbUpIcon,
			VariableIcon,
			PencilSquareIcon
		} from '@heroicons/vue/24/outline'
		import LayoutDefault from '@/layouts/LayoutDefault.vue'
		

		export default {
			name: 'PulllistPage', 
			components: {
				LayoutDefault,
				TrashIcon,
				InformationCircleIcon,
				CheckCircleIcon, 
				MinusCircleIcon,
				HandThumbUpIcon,
				ToggleSwitch,
				VariableIcon,
				PencilSquareIcon
			},
			data() {
				return {
					pulls: [],
					changing: '',
					sortByProvisional: false,
					sortByGrabbed: false
				}
			},
			computed: {
				sorted_pulls: function() {
					var sorter = ['matchstring'];
					if (this.sortByGrabbed) {
						sorter = ['last_grabbed','matchstring'];
					}
					var result = _.sortBy(this.pulls, sorter);
					if (this.sortByProvisional) {
						result = _.filter(result, function(o){ 
							return o.provisional;
						});
					}
					return result;
				}
			},
			created() {
				this.$watch(
					() => this.$route.params,
					() => {
						this.populate();
					}
					)
			},
			async mounted() {
				var routepath = 'book/'
				await this.get_url(routepath)
				.then((data) => {
					this.pulls.push(...data);
				});


			},
			methods: {
				async populate() {
					await this.get_url();
				},
				async removeProvisional(book) {
					book.provisional = false;
					await this.put_object('book/'+book.matchstring, book);
				},
				async setArcEnd(book) {
					await this.put_object('book/'+book.matchstring, book) 
					.then(() => {
						this.changing = '';
					});
				},
				async unwatchbook(book) {
					book.provisional = false;
					await this.delete_object('book/'+book.matchstring)
					.then(() => {
						this.pulls.splice(_.findIndex(this.pulls, function(o){  return o.matchstring == book.matchstring }), 1);

					});
				},
				toggleSortByGrabbed: function() {
					this.sortByGrabbed = !this.sortByGrabbed;
				},
				toggleSortByProvisional: function() {
					this.sortByProvisional = !this.sortByProvisional;
				}
			}
		}
	</script>