import { createWebHistory, createRouter } from "vue-router";
import PulllistPage from '../views/PulllistPage.vue'
import ThisweekPage from '../views/ThisweekPage.vue'
import WatchlistPage from '../views/WatchlistPage.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: PulllistPage
  },
  {
    path: '/thisweek',
    name: 'This Week',
    component: ThisweekPage
    // component: () => import(/* webpackChunkName: "Menu" */ '../views/MenuPage.vue')
  },
  {
    path: '/watchlist',
    name: 'Watchlist',
    component: WatchlistPage
  }
];

const router = createRouter({
  history: createWebHistory('/'),
  routes,
});

export default router;