<template>
	<LayoutDefault>

      <nav class="isolate inline-flex mb-2  -space-x-px rounded-md shadow-sm" aria-label="Pagination">
<a class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0" v-for="p in weeklist" :key="p" @click.prevent="toggleweek(p)">
	{{p}} 
</a>
      </nav>
<a href="#" class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0" @click.prevent="toggleweek('')" v-if="onlyweek != ''">x</a>

		<div
		v-for="x in current_pulllist"
		:key="x.matchstring"
		class="text-left md:mx-2 w-full lg:w-1/2 flex flex-row flex-nowrap mb-4 border-2  rounded-md relative" 
		:class="{'border-blue-200': x.provisional, 'border-green-200': parseInt(x.arc_last) == parseInt(x.next_number)}"
		>

		<div class="cover flex-none">
			<img 
			:src="x.coverimage" 
			alt="(cover)" 
			class="h-36 aspect-auto rounded-md"
			>
		</div>		

		<div class="maincontent flex-1  ml-4">

			

			<div class="text-xl sm:text-2xl mb-2 font-bold">
				<div class="inline-block align-middle">
					<a 
					:href="x.preview" 
					title="info from leagueofcomicgeeks.com" 
					target="_new"
					>
					<InformationCircleIcon 
					class="h-6 w-6 text-blue-500"
					/>
				</a>
			</div>
			{{x.name}} #{{x.next_number}}
		</div>




		<div>{{x.publisher}}</div>
		<div
		v-if="parseInt(x.arc_last) == parseInt(x.next_number) && changing != x.matchstring"
		class="absolute bottom-0 right-0 px-4 py-1 bg-green-200 rounded-tl-md"
		>
		<span>End of Arc</span>
	</div>
	<div
	v-else-if="x.provisional"
	class="absolute bottom-0 right-0 px-4 py-1 bg-blue-200 rounded-tl-md"
	>
	<span>Provisional</span>
</div>
<div class="absolute bottom-0 left-32">
	<div v-if="changing == x.matchstring">
		<input
		v-model="x.arc_last"
		type="text"
		class="w-8 rounded-md mr-2 border-2"
		><a
		href="#"
		class="inline-block mr-2 "
		@click.prevent="setArcEnd(x)"
		><CheckCircleIcon class="h-4 w-4 text-green-500"/></a><a
		href="#"
		class="inline-block mr-2 "
		@click.prevent="changing = ''"
		><MinusCircleIcon class="h-4 w-4 text-red-500"/></a>
	</div>
	<span v-else-if="!isNaN(parseInt(x.arc_last)) && parseInt(x.arc_last) >= parseInt(x.next_number)"><a
		href="#"
		@click.prevent="changing = x.matchstring"
		><VariableIcon class="h-6 w-6 text-blue-500 inline-block mr-1"/>set</a> to {{x.arc_last}}</span>
	<span v-else><a
		href="#"
		@click.prevent="changing = x.matchstring"
		><VariableIcon class="h-6 w-6 text-blue-500 inline-block mr-1"/>set</a></span>
	</div>
	<div>
		<span v-if="x.last_grabbed !== null">last grabbed: {{x.last_grabbed}} </span>
		<span v-else>last grabbed: never</span>
	</div>
</div>
<div class="options  flex-none">
	<div>
		<a
		href="#"
		@click.prevent="removeFromPulllist(x)"
		title="Grabbed it"
		><HandRaisedIcon  
		class="h-6 w-6 text-blue-500"
		/></a>
	</div>
	
	<div class="absolute bottom-6 mb-2">
		<a
		href="#"
		title="Skipped it"
		@click.prevent="removeFromPulllist(x)"
		><ForwardIcon class="h-6 w-6 text-blue-500"/></a>
	</div>

</div>
</div>
</LayoutDefault>	
</template>

<script>
	// import CurrentPulls from '@/components/CurrentPulls.vue'
	import _ from 'lodash';
	import { InformationCircleIcon, 
	HandRaisedIcon,
	CheckCircleIcon, 
	MinusCircleIcon,
	ForwardIcon,
	VariableIcon
} from '@heroicons/vue/24/outline'
import LayoutDefault from '@/layouts/LayoutDefault.vue'


// eventually the component below will ajax pull from https://d4lgzfxz8b.execute-api.us-west-2.amazonaws.com/main/book

export default {
	name: 'PulllistPage', 
	components: {
		LayoutDefault,
		InformationCircleIcon,
		HandRaisedIcon,
		CheckCircleIcon,
		MinusCircleIcon,
		ForwardIcon,
		VariableIcon
	},
	data() {
		return {
			pulls: [],
			changing: '',
			onlyweek: ''
		}
	},
	computed: {
		current_pulllist: function() {
			if (this.onlyweek == '') {
				return _.sortBy(_.filter(this.pulls, function(x) { return x.on_pulllist == true }), ['matchstring']);
			} else {
				var filter = {};
				filter.week = this.onlyweek;				
				// var shit = this.onlyweek;
				//var tmparray = _.filter(this.pulls, function(x) { return x.week == shit });
				// return _.sortBy(_.filter(tmparray, function(x) { return x.on_pulllist == true }), ['matchstring']);
				var tmplist = _.sortBy(_.filter(this.pulls, function(x) { return x.on_pulllist == true }), ['matchstring']);
				return _.sortBy(_.filter(tmplist, filter), function(o) { return o.matchstring });
			}
		},
		weeklist: function() {
			return _.uniq(_.map(this.current_pulllist, function(o){ return o.week })).sort();
		}
	},
	created() {
		this.$watch(
			() => this.$route.params,
			() => {
				this.populate();
			}
			)
	},
	async mounted() {
		var routepath = 'book/'
		await this.get_url(routepath)
		.then((data) => {
			this.pulls.push(...data);
		});


	},
	methods: {
		async populate() {
			await this.get_url();
		},
		async setArcEnd(book) {
			await this.put_object('book/'+book.matchstring, book) 
			.then((data) => {
				console.log('data was ', data);
				this.changing = '';
			});
		},
		async removeFromPulllist(book) {
			book.on_pulllist = false;
			var d = new Date;		    	
			book.last_grabbed = d.toDateString();
			await this.put_object('book/'+book.matchstring, book) 
			.then((data) => {
				console.log('data was ', data);
			});
		},
		toggleweek(thisweek) {
			if (thisweek != '') { 
				this.onlyweek = thisweek;
			} else {
				this.onlyweek = '';
			}
		}
	}
}
</script>