<template>
	<LayoutDefault>

		<ToggleSwitch
:is-checked="numberOnesOnly"
msg="Just First Issues"
@toggle-it="toggleNumberOnesOnly"
/>

		<ToggleSwitch
:is-checked="majorPublishers"
msg="Just Major Publishers"
@toggle-it="toggleMajorPublishers"
/>

		<div class="table w-full lg:w-3/4">

			<div
			v-for="x in sorted_weeklist"
			:key="x.matchstring"
			class="text-left table-row odd:bg-white even:bg-slate-100 hover:bg-red-100 "
			>


				<div class="cover flex-none table-cell">
					<img 
					:src="x.coverimage" 
					alt="(cover)" 
					class="h-36 aspect-auto rounded-md"
					>
				</div>		
				<div class="table-cell align-middle">
				{{x.name}} 
				<a
				:href="x.preview"
				target="_new"
				title="view info on leagueofcomicgeeks.com"
				class="inline-block"
				>
						<InformationCircleIcon 
						class="h-6 w-6 text-blue-500"
						/>
				</a> 
				</div>
				<div class="table-cell align-middle">
				{{x.next_number}} 
				</div>
				<div class="table-cell align-middle">
				({{x.publisher}}) 
				</div>
				<div class="table-cell align-middle">
				<a
				href="#"
				title="add to watchlist"
				class="inline-block lg:mr-6 "
				@click.prevent="watchbook(x)"
				>
					<EyeIcon 
					class="h-6 w-6 text-blue-500"
					/>
				</a> 
				<a
				href="#"
				title="add to watchlist provisionally"
				class="inline-block lg:mr-6"
				@click.prevent="watchbook(x,true)"
				>
					<PaperClipIcon
					class="h-6 w-6 text-blue-500"
					/>					
				</a>
			</div>
		</div>
	</div>

</LayoutDefault>
</template>

<script>
	import _ from 'lodash';
	import ToggleSwitch from '@/components/ToggleSwitch'
	import LayoutDefault from '@/layouts/LayoutDefault.vue'
	import { 
		InformationCircleIcon, 
		EyeIcon,
		PaperClipIcon
	} from '@heroicons/vue/24/outline'

	export default {
		name: 'ThisweekPage', 
		components: {
			LayoutDefault,
			ToggleSwitch,
			InformationCircleIcon,
			EyeIcon,
			PaperClipIcon
		},
		data() {
			return {
				weeklist: [],
				searchstring: '',
				numberOnesOnly: true,
				publisher: '',
				majorPublishers: true
			}
		},
		computed: {
			publishers: function() {
				if (this.weeklist.length > 0) {
					return _.sortBy(_.uniq(_.map(this.weeklist, 'publisher')), function(o) { return o });
				} else {
					return [];
				}
			},
			sorted_weeklist: function() {
				var filter = {};
				var inputList = this.weeklist;
				if (this.publisher != '') {
					filter.publisher = this.publisher;
				}
				if (this.numberOnesOnly) {
					filter.next_number = '1';
				}
				if (this.majorPublishers) {
					inputList = _.filter(this.weeklist, function(o) {
						var bigpubs = [
							'Image Comics',
							'AfterShock Comics',
							'BOOM! Studios',
							'Comixology',
							'DC Comics',
							'Dark Horse Comics',
							'Dynamite',
							'Heavy Metal',
							'IDW Publishing',
							'Marvel Comics',
							'Oni Press',
							'Skybound',
							'Top Cow Productions',
							'Artists Writers & Artisans Inc'
							]
						return bigpubs.includes(o.publisher);
					})
				}
				// return _.filter(this.weeklist, {next_number: '1'});
				// return _.filter(this.weeklist, {publisher: 'Image Comics'});
				return _.sortBy(_.filter(inputList, filter), function(o) { return o.matchstring });
				// return _.sortBy(this.weeklist, ['matchstring']);
			}
		},
		created() {
			this.$watch(
				() => this.$route.params,
				() => {
					this.populate();
				}
				)
		},
		async mounted() {
			var routepath = 'thisweek'
			await this.get_url(routepath)
			.then((data) => {
				this.weeklist.push(...data);
			});


		},
		methods: {
			async populate() {
				await this.get_url();
			},
			toggleNumberOnesOnly() {
				this.numberOnesOnly = !this.numberOnesOnly;
			},
			toggleMajorPublishers() {
				this.majorPublishers = !this.majorPublishers;
			},
			async watchbook(bookobj, is_provisional) {
				console.log(bookobj.provisional);
				if (is_provisional) {
					console.log('changing');
					bookobj.provisional = true
				}
				console.log(bookobj.provisional);
				bookobj.on_pulllist = true;
				console.log(bookobj.on_pulllist);

				
				// await this.get_cover(bookobj.preview) 
				// .then((data) => {
				// 	bookobj.coverimage = data.coverimage

				// 	for (var p in _.keys(bookobj)){
				// 		console.log(_.keys(bookobj)[p], bookobj[_.keys(bookobj)[p]]);
				// 	}



				// }).then(() => {
				// 	this.put_object('book/'+bookobj.matchstring, bookobj) 
				// 	.then((data) => {
				// 		console.log('data was ', data);
				// 	});

				// });

				this.put_object('book/'+bookobj.matchstring, bookobj) 
				.then((data) => {
					console.log('data was ', data);
				});


			}
		}
	}
</script>