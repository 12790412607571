<template>

  <nav class="w-full absolute top-0 z-50 flex items-center justify-between flex-wrap bg-red-200 p-0">

    <div class="text-2xl font-bold text-left ml-4">Newcomb</div>

    <router-link
    class="block mt-4 lg:inline-block lg:mt-0 px-4 py-2"
    :to="'/'"
    >Pull List</router-link>

    <router-link
    class="block mt-4 lg:inline-block lg:mt-0 px-4 py-2"
    :to="'/thisweek'"
    >This Week</router-link>

    <router-link
    class="block mt-4 lg:inline-block lg:mt-0 px-4 py-2"
    :to="'/watchlist'"
    >Watching</router-link>

  </nav>

  <div class="mainbody sm:w-11/12 mx-auto mt-4 sm:mt-100">



    <slot/>



  </div>
</template>

