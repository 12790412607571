<template>
  <div class="flex items-center justify-center w-full mb-12">
    
    <label
    :for="elementId"
    class="flex items-center cursor-pointer"
    >
    <!-- toggle -->
    <div class="relative">
      <!-- input -->
      <input
      :id="elementId"
      type="checkbox"
      class="sr-only"
      @change="$emit('toggleIt')"
      >
      <!-- line -->
      <div
      class="block  w-14 h-8 rounded-full"
      :class="[ isChecked ? 'bg-red-600' : 'bg-red-200']"
      ></div>
      <!-- dot -->
      <div class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
    </div>
    <!-- label -->
    <div class="ml-3 text-gray-700 font-medium">
      {{msg}}
    </div>
  </label>

</div>
</template>

<script>
  export default {
    name: 'ToggleSwitch',
    props: {
      msg: String,
      isChecked: Boolean
    },
    computed: {
      elementId() {
        var fuck = this.msg.toLowerCase().replace(/\s/g, '-');
        return fuck;
      }
    }
  }
</script>

<style>

  input:checked ~ .dot {
    transform: translateX(100%);
  }

</style>