
export default {
	install: (app) => {
		app.mixin({
			created() {
			},
			methods: {
				async get_url(explicitpath) {
	      // this.tabsList = [];
	      // this.menuList = [];
	      // this.itemsList = [];

					try {
						const res = await fetch("https://d4lgzfxz8b.execute-api.us-west-2.amazonaws.com/main/"+explicitpath, {
							headers: {
								'Accept': 'application/json'
							}
						})

						if (!res.ok) {
							const message = `An error has occured: ${res.status} - ${res.statusText}`;
							throw new Error(message);
						}

						const data = await res.json();
						return data;
	           // const data = $get_url('menu/pizza')
	           // this.itemsList.push(...data.items);
	           // this.menuList.push(...data.menu);
	           // this.tabsList.push(...data.tabs);
	           // this.displayTitle = data.display_title;
	           // this.selectedMenu = data.selected_menu;
	           // this.selectedTab = data.selected_tab; 
	           // this.$refs.myRanchNav.closethemenu();

					} catch (err) {
						console.log(err);
					}
				},
				async get_cover(previewurl) {
					try {
						const res = await fetch("https://d4lgzfxz8b.execute-api.us-west-2.amazonaws.com/main/coverimage", {
							method: 'post',
							headers: {
								'Accept': 'application/json'
							},
							body: JSON.stringify({previewurl: previewurl})
						}
						)

						if (!res.ok) {
							const message = `An error has occured: ${res.status} - ${res.statusText}`;
							throw new Error(message);
						}

						const data = await res.json();
						return data;

					} catch (err) {
						console.log(err);
					}
				},  
				async put_object(explicitpath, newobj) {

					try {
						const res = await fetch("https://d4lgzfxz8b.execute-api.us-west-2.amazonaws.com/main/"+explicitpath, {
							method: 'post',
							headers: {
								'Accept': 'application/json'
							},
							body: JSON.stringify(newobj),
						}
						)

						if (!res.ok) {
							const message = `An error has occured: ${res.status} - ${res.statusText}`;
							throw new Error(message);
						}

						const data = await res.json();
						return data;

					} catch (err) {
						console.log(err);
					}
				},
				async delete_object(explicitpath) {

					try {
						const res = await fetch("https://d4lgzfxz8b.execute-api.us-west-2.amazonaws.com/main/"+explicitpath, {
							method: 'delete',
							headers: {
								'Accept': 'application/json'
							}
						}
						)

						if (!res.ok) {
							const message = `An error has occured: ${res.status} - ${res.statusText}`;
							throw new Error(message);
						}

						const data = await res.json();
						return data;

					} catch (err) {
						console.log(err);
					}
				}
			},
		});
	},
};